import React, { useState } from 'react';
import classnames from 'classnames';
import { Plugins } from '@capacitor/core';
import { StripePlugin } from '@capacitor-community/stripe';
import applePayClient from 'api/apple-pay-client';

import style from './apple-pay-button.module.scss';
import { getUAInfo } from 'services/user-agent.service';

export const Stripe = Plugins.Stripe as StripePlugin;

// Warning: This plugin doesn't have web implementation.
// Consider to replace it with propper component on WEB app.
// It could mess other capacitor plugins on WEB.
const { isiOS, isSafari } = getUAInfo(window.navigator);

if (isiOS && isSafari) {
    // Stripe.setPublishableKey({
    //     key: 'pk_live_51QnNYZCjjRgT1ij4jNejeuhTbUFbkCjEt6ThuAtOeLYUPkNAWARf7gbiLBzAbWirhR6cFdRFZxxirsseD8uWBsbM00qavNgWWZ',
    // });
    Stripe.initialize({
        publishableKey:
            'pk_live_51QnNYZCjjRgT1ij4jNejeuhTbUFbkCjEt6ThuAtOeLYUPkNAWARf7gbiLBzAbWirhR6cFdRFZxxirsseD8uWBsbM00qavNgWWZ',
    });
}

type ProductProps = {
    productID: string | number;
    discountCode?: string;
    currency: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess?: () => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onCancel?: () => any;
};

export const ApplePayButton = ({
    productID,
    discountCode,
    currency,
    onSuccess,
    onCancel,
}: ProductProps): JSX.Element => {
    const [loading, setLoading] = useState(false);

    const makePayment = async (): Promise<void> => {
        setLoading(true);
        try {
            const orderData = await applePayClient.createOrder({
                productId: productID,
                code: discountCode,
            });

            const { order_id, discountPrice } = orderData;

            const { client_secret: clientSecret } =
                await applePayClient.paymentIntent(orderData);

            await Plugins.Stripe.confirmPaymentIntent({
                clientSecret,
                applePayOptions: {
                    merchantId: 'merchant.no.fuelbox.app',
                    country: 'NO',
                    currency: currency.toLocaleUpperCase(),
                    items: [
                        {
                            label: 'FuelBox',
                            amount: discountPrice,
                        },
                    ],
                },
            });

            const confirmed = await applePayClient.confirmOrder({ order_id });

            if (confirmed) {
                Plugins.Stripe.finalizeApplePayTransaction({ success: true });

                if (onSuccess) onSuccess();
            } else {
                Plugins.Stripe.finalizeApplePayTransaction({ success: false });

                if (onCancel) onCancel();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <button
            className={classnames(
                style['apple-pay-button'],
                style['apple-pay-button-black']
            )}
            onClick={makePayment}
            disabled={loading}
        />
    );
};
